import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';

import logo from '../images/neele-codes.svg';

const StyledHeader = styled.header`
  padding: 12.8rem 0 15rem;

  @media (max-width: 768px) {
    padding: 9rem 0 8rem;
  }

  @media (max-width: 550px) {
    padding: 7rem 0 6rem;
  }
`;

const Logo = styled.img`
  max-width: 77.4rem;
  display: block;
  margin: 0 auto;
`;

export const Header = ({siteTitle}) => (
  <StyledHeader>
    <h1>
      <Link to="/">
        {/* {siteTitle} */}
        <Logo src={logo} alt="Neele codes" />
      </Link>
    </h1>
  </StyledHeader>
);
