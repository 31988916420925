import React from 'react';
import styled from 'styled-components';

const StyledContent = styled.div`
  max-width: 107.5rem;
  margin: 0 auto;
  padding: 0 3.2rem;

  @media (max-width: 550px) {
    padding: 0 2rem;
  }
`;

export const Content = ({children}) => (
  <StyledContent>{children}</StyledContent>
);
