/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import styled, {createGlobalStyle} from 'styled-components';

import {Content} from './Content';
import {Header} from './Header';

import './layout.css';

const GlobalStyle = createGlobalStyle`
  body {
    color: ${props => (props.theme === 'light' ? '#000000' : '#ffffff')};
  }

  footer {
    text-align: center;
    margin: 2rem 0;
    font-weight: 500;
  }
`;

const SocialMedia = styled.section`
  font-weight: 500;
  margin-top: 2rem;
  text-align: right;
  font-size: 1.4rem;

  a {
    color: #000;
    text-decoration: none;
    position: relative;
    display: inline-block;
    margin: 0 0.4rem;

    &:hover {
      &:before {
        transform: scaleY(1) translate(-50%, 0);
      }
    }

    &:before {
      z-index: -1;
      content: '';
      background: #eea600;
      height: 100%;
      width: 110%;
      position: absolute;
      bottom: 0rem;
      left: 50%;
      transform: scaleY(0.3) translate(-50%, 0);
      transform-origin: left bottom;
      transition: all 0.1s ease-out;
    }

    &:nth-child(4n - 2) {
      &:before {
        background: #7647a3;
      }
    }

    &:nth-child(3n -2) {
      &:before {
        background: #dd1e84;
      }
    }
  }
`;

const Layout = ({children}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyle theme="light" />
      <Content>
        <SocialMedia>
          <a href="https://github.com/helloneele">github</a>{' '}
          <a href="https://twitter.com/helloneele">twitter</a>{' '}
          <a href="https://www.linkedin.com/in/neele-barthel-6b4742142/">
            linkedin
          </a>{' '}
          <a href="https://www.instagram.com/helloneele/">instagram</a>{' '}
          <a href="mailto:contact@neele.codes">email</a>
        </SocialMedia>
        <Header siteTitle={data.site.siteMetadata.title} />

        <main>{children}</main>
        <footer>Neele Barthel © {new Date().getFullYear()}</footer>
      </Content>
    </>
  );
};

export default Layout;
